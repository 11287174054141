import React from "react"
import Layout from "../components/layout"
import RecentPosts from "../components/recent-posts"
import { graphql, Link } from "gatsby"

export default ({data}) => (
  <Layout>
    <h2>Welcome to Noteimals: Teaching Kids Music</h2>
    <div className="clear-block">
      <div id="node-4" className="node">
        <div className="content clear-block">
          <div style={{float: "right", padding: "10px 0 10px 30px", width: "400px"}}>
            <object width="400" height="300"><param name="movie" value="http://www.youtube.com/v/Fo-4ykfVetA?fs=1&amp;hl=en_US&amp;rel=0"></param><param name="allowFullScreen" value="true"></param><param name="allowscriptaccess" value="always"></param><embed src="http://www.youtube.com/v/Fo-4ykfVetA?fs=1&amp;hl=en_US&amp;rel=0" type="application/x-shockwave-flash" width="400" height="300" allowscriptaccess="always" allowFullScreen={true}/></object>
            <br/><br/>
            <RecentPosts data={data}/>
          </div>

The basic skills needed to play an instrument or sing a song of Note Reading and Timing, taught in our
“<Link to="/books/Beginning-Note-Reading">Note Reading for Children</Link>” and “<Link to="/books/Beginning-Timing">Timing for Children</Link>”.

<p>Note reading standard music notation is often difficult for many beginning students, and without being able to read notes, timing is almost impossible. The Animal Note Method of teaching music is based on associative learning.  The oval of each note of the Grand Staff is modified to resemble a familiar and friendly animal that is easily recognized.  A suggested short story about the location of each Animal Note’s position on the Grand Staff is part of the educational experience.  These stories give word clues that when associated with the standard music notes provide a successful transition, thus making learning to read notes much easier and actually fun to learn and to teach. </p>

<p>It is our purpose and goal to provide you the teaching materials and useful information for this wonderful new way of introducing music to children, and adults that want to play, but always declare, "It's impossible, I just can't read music."</p>

<p>This method allows you to successfully start a toddles through adults on the piano and six and up violin and lead guitar by playing the melody of a song.  The child does not just learn to move their fingers over an instrument, but to read the notes located on the Grand Staff, find the note’s correct location on the instrument, and play it.</p>

<p>An individual of any age, who has difficulty learning to read music, will enjoy their experience in learning and the success they can obtain with this method. The materials are designed so a person with no musical training can teach the Toddler in their life to play the piano and both the teacher and child will have fun while learn.  It builds a strong foundation in note reading and timing, introduce some basic elements of theory, and provide a successful way to move on to playing music in standard music notation.  I know you will rejoice as you witness your students’ achievements.</p>

<br/><br/>

  <h3>Testimonials</h3><br/>

  <i>I have taught piano for many years,  mostly to adults, as I found teaching young children frustrating, due to the difficulty in teaching them to read. I feel that the popular method books instill bad habits and promote a lazy approach to reading. Just recently I happened upon Glenna Cook's "NOTEIMALS" and it has forever changed my teaching career. I am now teaching 3 year olds to read music! Glenna's method is truly revolutionary. It is so natural and so simple. Even a non musician parent could start to help their child learn to read by investing in the Noteimals books. It is truly a fool proof system. Not only do the children learn, but they LOVE it!</i><br/>
  - Diana Cobb (Scottsdale, Az)
  <br/><br/>
    <i>Thanks! Got it and we love it!! MY mom has played piano all her life and loves it!!!! Great idea!!!</i><br/>
    - Eric
    <br/><br/>

      <i>I combined your book with simple note stickers from Sam Ash. The unprompted response from my student today was: "This is fun! It makes it easy." She then proceeded to have the longest focus yet in a lesson, and played! As did her four-year-old sister!! I'm so excited. Thanks again. I'll be sharing your info with a friend of mine who has many more students than I have. </i><br/>
      Nancee (New York)
      <br/><br/>

      </div>

        <div className="clear-block">
          <div className="meta">
          </div>

        </div>

      </div>
    </div>
  </Layout>
)


export const query = graphql`query {
 allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "//blog//"}}
    sort: { order: DESC, fields: [frontmatter___date] }
    limit: 10
  ) {
  edges {
    node {
      frontmatter {
        title
        path
        date
      }
    }
  }
} 
}`
