import React from "react"
import { Link } from "gatsby"

export default ({ data }) => (
  <div style={{textAlign: "left"}}>
    Recent blog posts:
    <table><tbody>
    { data.allMarkdownRemark.edges.map(edge => {
      const {frontmatter} = edge.node
      return (
        <tr key={frontmatter.path}>
          <td align="right" width="90" nobr="" style={{ whiteSpace: 'nowrap', verticalAlign: 'top', margin: 0, padding: 0, textAlign: 'left' }}><i>{frontmatter.date}</i>&nbsp;</td>
          <td width="280" style={{ margin: 0, padding: 0 }}><Link to={frontmatter.path}>{frontmatter.title}</Link></td>
        </tr>
      )
    })}
    </tbody></table>
  </div>
)

